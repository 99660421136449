html,
body,
#root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: lightskyblue;
}

.label>div {
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    background: #00000088;
    color: white;
    padding: 15px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 30px;
    user-select: none;
}